<template>
    <v-container fluid>
        <v-form @submit.prevent="getCinemaOrders('search')">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn @click="setBack()"  class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ $t('orders') }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="transaction_number" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="transaction_number" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-shopping"
                                                  :label="$t('order_number')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="total" rules="min_value:0"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="total" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-sack"
                                                  :label="$t('order_total')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <v-dialog ref="paymentDateDialogFrom" v-model="paymentDateDialogFrom" width="290px">
                                    <v-date-picker ref="pickerExpirationFrom" v-model="payment_date_from"
                                                   :max="payment_date_to ? $moment(payment_date_to).format('YYYY-MM-DD') : $moment().add(10, 'y').format('YYYY-MM-DD')"
                                                   :locale="lang" @change="paymentDateDialogFrom = false"></v-date-picker>
                                </v-dialog>
                                <ValidationProvider ref="payment_date_from" rules="date_format"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="payment_date_from" :error-messages="errors"
                                                  :disabled="loading" :label="$t('date_from')"
                                                  :error="!valid"
                                                  prepend-icon="mdi-calendar" color="primary" readonly
                                                  @click.stop="paymentDateDialogFrom = !paymentDateDialogFrom"
                                                  clearable @click:clear="payment_date_from = null"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <v-dialog ref="paymentDateDialogTo" v-model="paymentDateDialogTo" width="290px">
                                    <v-date-picker ref="pickerExpirationTo" v-model="payment_date_to"
                                                   :min="payment_date_from ? $moment(payment_date_from).format('YYYY-MM-DD') : $moment().subtract(10, 'y').format('YYYY-MM-DD')"
                                                   :locale="lang" @change="paymentDateDialogTo = false"></v-date-picker>
                                </v-dialog>
                                <ValidationProvider ref="payment_date_to" rules="date_format"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="payment_date_to" :error-messages="errors" :disabled="loading"
                                                  :label="$t('date_to')" prepend-icon="mdi-calendar"
                                                  :error="!valid"
                                                  color="primary" readonly clearable
                                                  @click.stop="paymentDateDialogTo = !paymentDateDialogTo"
                                                  @click:clear="payment_date_to = null"></v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="transaction_type" rules="min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="transaction_type" :disabled="loadingTransactionType"
                                              :items="transaction_typeItems" :error-messages="errors"
                                              prepend-icon="mdi-home-variant-outline" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('transaction_type')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="transaction_status" rules="min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="transaction_status" :disabled="loadingTransactionStatus"
                                              :items="transaction_statusItems" :error-messages="errors"
                                              prepend-icon="mdi-home-variant-outline" :error="!valid"
                                              color="primary" item-text="name" item-value="id"
                                              :label="$t('transaction_status')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>





                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)" hide-details
                                          dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="orderItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalCinemaOrders" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"
                                       @click="showOrder(item)" icon>
                                    <v-icon>mdi-card-text-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('more_detailed')}}</span>
                        </v-tooltip>

                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>


        <v-dialog
            v-model="dialog_detail"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card v-if="(detail) ? true : false">
                <v-toolbar
                    dark
                    color="primary"
                >
                    <v-btn
                        icon
                        dark
                        @click="dialog_detail = false"
                    >
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-toolbar-title class="px-0">Заказ № {{detail.transaction_number}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        dark
                        @click="dialog_detail = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-list


                >

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Данные оплаты</v-list-item-title>
                            <v-list-item-subtitle>Сумма заказа: {{detail.total}}</v-list-item-subtitle>
                            <v-list-item-subtitle>Дата заказа: {{detail.created_at}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="detail.payment_date ? true : false">Дата оплаты: {{detail.payment_date}}</v-list-item-subtitle>
                            <v-list-item-subtitle>Использовано бонусов: {{detail.points}}</v-list-item-subtitle>
                            <v-list-item-subtitle>Использовано денег: {{detail.amount}}</v-list-item-subtitle>
                            <v-list-item-subtitle>Тип транзакции: {{detail.transaction_type.name}}</v-list-item-subtitle>
                            <v-list-item-subtitle>Статус транзакции: {{detail.transaction_status.name}}</v-list-item-subtitle>
                            <v-list-item-subtitle style="color: red">{{detail.transaction_error}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Данные клиента</v-list-item-title>
                            <v-list-item-subtitle>{{detail.client.name}}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{detail.client.phone}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Данные компании</v-list-item-title>
                            <v-list-item-subtitle>{{detail.company.name}}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{detail.company.description}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>



                </v-list>
                <v-divider></v-divider>
                <v-list

                >
                    <v-subheader>Данные заказ</v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-if="detail.order_shopping_center_service ? true : false">{{detail.order_shopping_center_service.title}}</v-list-item-title>

                            <v-row v-if="detail.order_fitness ? true : false">
                                <v-col :cols="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 12 : 6"
                                       v-for="(item, i) in detail.order_fitness"
                                       :key="i">
                                    <v-card
                                        outlined
                                        class="rounded-lg"
                                    >
                                        <v-list-item >
                                            <v-list-item-content>
                                                <v-list-item-title class="mb-1">
                                                    Покупка абонемента  {{item.type_title}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="mb-1">
                                                    На срок  {{ item.title }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle class="mb-1">
                                                    Время посещения {{ item.visit_time }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>

                                    </v-card>
                                </v-col>


                            </v-row>

                            <v-row v-if="detail.order_parks ? true : false">
                                <v-col :cols="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 12 : 6"
                                       v-for="(item, i) in detail.order_parks"
                                       :key="i">
                                    <v-card
                                        outlined
                                        class="rounded-lg"
                                    >
                                        <v-list-item >
                                            <v-list-item-content>
                                                <v-list-item-title class="mb-1">
                                                    Пополнение карты  {{item.park_card.number}}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                    </v-card>
                                </v-col>


                            </v-row>

                            <v-row v-if="detail.order_products ? true : false">
                                <v-col :cols="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 12 : 6"
                                       v-for="(item, i) in detail.order_products"
                                       :key="i">
                                    <v-card
                                        outlined
                                        class="rounded-lg"
                                    >
                                        <v-list-item >
                                            <v-list-item-content>
                                                <v-list-item-title class="mb-1">
                                                    {{ item.name }} {{ item.product_size ? 'Размер: ' + item.product_size : ''}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="mb-1">
                                                    Цена за штутку {{ item.price }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle class="mb-1">
                                                    Колличесвто штук {{ item.count }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle class="mb-1">
                                                    Итого {{ item.cost }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle>
                                                    {{ item.short_description ? item.short_description : $t('not_accepted_yet') }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-avatar

                                                size="68" class="border1_B3B3B3"
                                            >
                                                <img v-if="item.photo " :src="item.photo ? item.photo : defaultAvatar" >
                                                <v-icon v-else  size="40" class="color_B3B3B3">
                                                    ${{'settingsIcon'}}
                                                </v-icon>

                                            </v-list-item-avatar>
                                        </v-list-item>

                                    </v-card>
                                </v-col>


                            </v-row>
                        </v-list-item-content>
                    </v-list-item>








                </v-list>

                <v-divider v-if="detail.client_certificates ? true : false"></v-divider>
                <v-list
                    v-if="detail.client_certificates ? true : false"
                >
                    <v-subheader>Данные сертификатов</v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-row >
                                <v-col :cols="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 12 : 6"
                                       v-for="(item, i) in detail.client_certificates"
                                       :key="i">
                                    <v-card
                                        outlined
                                        class="rounded-lg"
                                    >
                                        <v-list-item >
                                            <v-list-item-content>
                                                <v-list-item-title class="mb-1">
                                                    {{ item.title }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ item.short_description ? item.short_description : $t('not_accepted_yet') }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-avatar

                                                size="68" class="border1_B3B3B3"
                                            >
                                                <img v-if="item.photo " :src="item.photo ? item.photo : defaultAvatar" >
                                                <v-icon v-else  size="40" class="color_B3B3B3">
                                                    ${{'settingsIcon'}}
                                                </v-icon>

                                            </v-list-item-avatar>
                                        </v-list-item>

                                        <v-card-actions>
                                            <v-list-item-title v-if="item.used">Сертификата использован</v-list-item-title>
                                            <v-btn
                                                v-if="!item.used"
                                                outlined
                                                :disabled="loading" :loading="loading"
                                                color="indigo"
                                                class="rounded-lg but_enter mt-4"

                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                @click="confirmUseCertificate(item)"
                                            >
                                                Подтвердить использование сертификата
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>

                                    </v-card>
                                </v-col>


                               </v-row>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>

        <v-overlay :value="loading" z-index="10" color="#424242">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";

    export default {
        name: "CinemaOrders",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                sortBy: "id",
                sortDir: false,
                loading: false,
                loadingCompanies: false,
                order: null,
                options: {},
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalCinemaOrders: 0,
                orderItems: [],
                headers: [
                    {
                        text: this.$t('order_number'),
                        align: "left",
                        sortable: true,
                        value: "transaction_number"
                    },
                    {
                        text: this.$t('order_total'),
                        align: "left",
                        sortable: true,
                        value: "total"
                    },

                    {
                        text: this.$t('transaction_type'),
                        align: "left",
                        sortable: true,
                        value: "transaction_type.name"
                    },
                    {
                        text: this.$t('transaction_status'),
                        align: "left",
                        sortable: true,
                        value: "transaction_status.name"
                    },
                    {
                        text: this.$t('payment_date'),
                        align: "left",
                        sortable: true,
                        value: "payment_date"
                    },
                    {
                        text: this.$t('date_created'),
                        align: "left",
                        sortable: true,
                        value: "created_at"
                    },
                    {
                        text: this.$t('more_detailed'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
                transaction_number: null,
                transaction_type: null,
                transaction_typeItems: [],
                loadingTransactionType: false,
                transaction_status: null,
                transaction_statusItems: [],
                loadingTransactionStatus: false,
                loadingTypeCompany: false,
                total: null,
                paymentDateDialogFrom: false,
                payment_date_from: null,
                payment_date_to: null,
                paymentDateDialogTo: false,
                dialog_detail: false,
                detail: null,
            };
        },
        computed: {
            ...mapGetters(['lang',  'itemsPerPage', 'perPageItems','defaultAvatar']),
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
            this.getTransactionType()
            this.getTransactionStatus()
        },
        watch: {
            options: {
                handler() {
                    this.getCinemaOrders()
                },
                deep: false
            },
        },
        methods: {
            async showOrder(item) {

                let params = {};
                this.loading = true
                await this.$http
                    .get(`partner/cinema_order/${item.id}`, {
                        params: params,
                    })
                    .then(res => {

                        this.detail = res.body.data
                        this.dialog_detail = true;
                    })
                    .catch(err => {
                        this.detail = {};
                        this.$toastr.error(this.$t('failed_to_get_list_transaction_types'))
                    })
                    .finally(end => {
                        this.loading = false
                    });
            },
            async getTransactionType() {
                this.loadingTransactionType = true
                let params = {};
                params.filter = 'all';
                await this.$http
                    .get(`partner/transaction_type`, {
                        params: params,
                    })
                    .then(res => {
                        this.transaction_typeItems = res.body.data
                    })
                    .catch(err => {
                        this.transaction_typeItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_transaction_types'))
                    })
                    .finally(end => {
                        this.loadingTransactionType = false
                    });
            },
            async getTransactionStatus() {
                this.loadingTransactionStatus = true
                let params = {};
                params.filter = 'all';
                await this.$http
                    .get(`partner/transaction_status`, {
                        params: params,
                    })
                    .then(res => {
                        this.transaction_statusItems = res.body.data
                    })
                    .catch(err => {
                        this.transaction_statusItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_transaction_transaction_statuses'))
                    })
                    .finally(end => {
                        this.loadingTransactionStatus = false
                    });
            },
            async getCinemaOrders(type) {
                this.loading = true;
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.transaction_number) {
                    params.transaction_number = this.transaction_number;
                }
                if (this.transaction_type) {
                    params.transaction_type = this.transaction_type;
                }
                if (this.transaction_status) {
                    params.transaction_status = this.transaction_status;
                }
                if (this.total) {
                    params.total = this.total;
                }

                if (this.payment_date_to) {
                    params.payment_date_to = this.payment_date_to;
                }
                if (this.payment_date_from) {
                    params.payment_date_from = this.payment_date_from;
                }



                await this.$http
                    .get("partner/cinema_order", {
                        params: params,
                    })
                    .then(res => {
                        this.orderItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalCinemaOrders = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.orderItems = []
                        this.totalCinemaOrders = 0
                        this.$toastr.error(this.$t('failed_to_get_list_orders'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async confirmUseCertificate(item) {
                if (confirm(this.$t('delete_order'))) {
                    var _this = this
                  //  this.loading = true;
                    item.used = true
                    // await this.$http
                    //     .put(`partner/certificate/${item.id}/confirmU`)
                    //     .then(res => {
                    //         this.$toastr.success(this.$t('order_has_been_deleted'))
                    //         this.getCinemaOrders()
                    //     })
                    //     .catch(err => {
                    //         this.$toastr.error(this.$t('order_has_not_been_deleted'))
                    //         if (err && err.body && err.body.message) {
                    //             for (let prop in err.body.errors) {
                    //                 if (hasOwnProperty.call(err.body.errors, prop)) {
                    //                     if (_this.$refs[prop]) {
                    //                         _this.$refs[prop].setErrors([
                    //                             err.body.errors[prop][0]
                    //                         ])
                    //                     }
                    //                 }
                    //             }
                    //             if (!err.body.errors) {
                    //                 this.$toastr.error(err.body.message)
                    //             }
                    //         }
                    //     })
                    //     .finally(end => {
                    //         this.loading = false
                    //     })
                }
            },
            setBack(){
                window.history.back();
            },
        }
    }
</script>
